<template>
  <DashboardTemplate>
    <div class="pd-x-5">
      <el-row>
        <el-col :span="24">
          <div class="is-flex ai-center js-between">
            <h2>ข้อมูลผู้ดูแลระบบ</h2>
            <div>
              <el-button
                type="primary"
                @click="$router.push(`/system/admin/add`)"
                >เพิ่มผู้ดูแลระบบ</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form ref="filter" :model="filter" label-position="top">
            <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
              <el-row :gutter="20">
                <el-col :span="6" :offset="0">
                  <el-select
                    class="w-100"
                    @change="handleCurrentChange(1, 200)"
                    v-model="filter.role"
                    clearable
                    placeholder="เลือกสิทธิ์"
                  >
                    <el-option label="Admin" :value="60"> </el-option>
                    <el-option label="System Admin" :value="50"> </el-option>
                    <el-option label="Support" :value="40"> </el-option>
                  </el-select>
                </el-col>
                <el-col :span="6" :offset="0">
                  <el-input
                    v-model="filter.search"
                    placeholder="ค้นหา ชื่อ/นามสกุล/อีเมล"
                    clearable
                    @input="handleCurrentChange(1, 600)"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-col>
        <el-col :span="24" :offset="0">
          <el-table
            border
            :data="userList"
            v-loading="isLoading"
            style="width: calc(100% - 5px)"
          >
            <template slot="empty">
              <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
            </template>

            <el-table-column
              prop="name"
              label="ชื่อ"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="surname"
              label="นามสกุล"
              align="center"
              header-align="center"
            >
            </el-table-column>

            <el-table-column
              prop="email"
              label="อีเมล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="role"
              label="Role"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.role == 60
                    ? "Admin"
                    : scope.row.role == 50
                    ? "System Admin"
                    : "Support"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="สมัครเมื่อ"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.createdAt != 6">
                  {{ moment(scope.row.createdAt).format("lll น.") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label=""
              align="center"
              header-align="center"
              width="70px"
            >
              <template slot-scope="scope">
                <el-dropdown trigger="click" class="mg-r-6">
                  <span class="el-dropdown-link">
                    <i
                      class="
                        fas
                        fa-ellipsis-v
                        mg-l-6
                        color-grey
                        cr-pointer
                        hover-primary
                      "
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="color-danger">
                      <router-link
                        class="text-grey"
                        :to="`/system/admin/edit/${scope.row.id}`"
                      >
                        แก้ไขข้อมูล
                      </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item divided class="color-danger"
                      ><a
                        class="text-grey"
                        target="_blank"
                        @click="confirmDelete(scope.row)"
                      >
                        ลบข้อมูล
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <div class="mg-y-3">
            <div class="block text-center">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[15, 30, 50, 100, 500]"
                :page-size="15"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalUser"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import userService from "@/service/userListService";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate
  },
  mounted() {
    this.fetchUserList();
  },
  data() {
    return {
      userList: [],
      totalUser: 0,
      filter: {
        search: "",
        role: "",
        limit: 15,
        offset: 0
      },
      activeName: "admin",
      currentPage: 1,
      debounce: null,
      isLoading: false
    };
  },
  methods: {
    confirmDelete(data) {
      this.$confirm(
        `ต้องการลบ ${data.name} ${data.surname} หรือไม่?`,
        "Warning",
        {
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          type: "warning"
        }
      )
        .then(async () => {
          await this.deleteAdmin(data.id);
          this.$message({
            type: "success",
            message: "ลบข้อมูลสำเร็จ"
          });
          this.handleCurrentChange(this.currentPage);
        })
        .catch(() => {});
    },
    async deleteAdmin ( id )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      return await HTTP.delete(`system/admin/emp/${id}`).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
    },
    handleSizeChange(val) {
      this.filter.limit = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val, time = 0) {
      this.isLoading = true;
      this.currentPage = val;
      let end = this.currentPage * this.filter.limit;
      let start = (this.currentPage - 1) * this.filter.limit;
      this.filter.offset = start;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchUserList();
      }, time);
    },
    async fetchUserList() {
      this.isLoading = true;
      let obj = {
        ...this.filter
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`system/admin/emps`, obj).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        this.userList = res.data.obj;
        this.totalUser = res.data.total;
      } else {
      }
      this.isLoading = false;
    }
  }
};
</script>