// @ts-nocheck
import { HTTP } from "@/service/axios";

var errorMsg = {
  data: {
    data: "",
  },
  success: false,
};
class userService {
  async getUserList ( token, obj )
  {
    HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
    HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
    let res = await HTTP.post(`users`, obj).catch((e) => {
      errorMsg.data.data = e;

      console.log("API users catch", e);
      return errorMsg;
    });
    if (res.data.success || !res.data.success) {
      return res;
    } else {
      return errorMsg;
    }
  }

  async getYear ( token )
  {
    HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
    HTTP.defaults.headers.common.Authorization = `Bearer ${token}`;
    let res = await HTTP.get(`years`).catch((e) => {
      errorMsg.data.data = e;

      console.log("API years catch", e);
      return errorMsg;
    });
    if (res.data.success || !res.data.success) {
      return res;
    } else {
      return errorMsg;
    }
  }
}

export default new userService();
